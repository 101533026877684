import {
  whiteColor,
  containerFluid,
  mrAuto,
  mlAuto,
} from "assets/jss/material-kit-pro-react";
// import starBulletin from "ps-assets/img/star-bulletin.png";

export default function (theme) {
  return {
    container: {
      width: `auto`,
    },
    containerFluid,
    mrAuto,
    mlAuto,
    topBlueStrip: {
      width: `100%`,
      backgroundColor: `#2980b9`,
      height: `6px`
    },
    headerWrapper: {
      backgroundColor: whiteColor,
      boxShadow: `0px 2px 5px #ccc`,
    },
    etabs: {
      listStyle: `none`,
      display: `block`,
      margin: 0,
      fontSize: `1vw`,
      padding: `0`,
      border: 0,
      [theme.breakpoints.down(`sm`)]: {
        display: `flex`
      }
    },
    tab: {
      display: `inline-block`,
      zoom: `1`,
      background: `#eee`,
      border: `solid 1px #999`,
      borderBottom: `none`,
      borderRadius: `0.32vw 0.32vw 0 0`,
      marginRight: `3px`,
      "& p": {
        fontSize: `10px`,
        display: `block`,
        padding: `5px 10px`,
        outline: `none`,
        textAlign: `center`,
        textDecoration: `none`,
        color: `#000`,
        cursor: `pointer`,
        margin: `0px`,
        fontFamily: `'Open Sans', sans-serif`,
        fontWeight: `300`
      },
    },
    activeTab: {
      background: `#fff`,
      position: `relative`,
      top: `1px`,
      border: `solid 1px #666`,
    },
    contentTabs: {
      width: `auto`,
      paddingRight: `1.2vw`,
    },
    formDiv: {
      fontSize: "12px !important",
      width: `auto`,
      marginLeft: `20px`,
      [theme.breakpoints.down(`sm`)]: {
        marginLeft: `0px`,
      },
      "& span": {
        color: `#0000FF`,
        fontSize: `12px`,
        fontFamily: `'Open Sans', sans-serif`,
        [theme.breakpoints.down(`sm`)]: {
          fontSize: `14px`,
        }
      },
    },
    content: {
      display: `flex`,
      paddingBottom: `10px`,
      width:`fit-content`,
      ...mrAuto,
      ...mlAuto,
      [theme.breakpoints.down(`sm`)]: {
        display: `block`,
      }
    },
    contentDiv: {
      backgroundColor: whiteColor,
      marginTop: `30px`,
      width: '70%',
      [theme.breakpoints.down(`md`)]: {
        marginTop: `4px`,
        width: `auto`,
      },
    },
    panelContainer: {
      overflow: `auto`,
      height: `335px`,
      background: `#fff`,
      border: `solid #666 1px`,
      padding: `5px 10px 10px 10px`,
      color: `#666`,
      fontFamily: `'Open Sans', sans-serif`
    },
    whyStar: {
      "& h1": {
        fontSize: `0.9vw`,
        fontWeight: `700`,
        "@media (max-width: 992)": {
          fontSize: `1.3vw !important`,
        }
      },
      "& b": {
        color: `#666`,
        fontFamily: `'Open Sans', sans-serif`
      }
    },
    whyStarDetails: {
      marginTop: `10px`,
      [theme.breakpoints.down(`sm`)]: {
        width: `auto`,
      },
      "& div": {
        [theme.breakpoints.down(`sm`)]: {
          display: `flex`
        },
        "& p": {
          marginBottom: `0px`,
          fontFamily: `'Open Sans', sans-serif`,
          fontSize: `12px`
        },
        "& img": {
          width: '1.6vw',
          height: '1.3vw',
          paddingRight: '0.3vw',
          float: 'left',
          [theme.breakpoints.down(`sm`)]: {
            height: `auto`,
            width: `auto`,
            marginBottom: `auto`
          }
        }
      },
    },
    productOffering: {
      fontSize: `12px`,
      "& div": {
        marginTop: `10px`,
        "& p": {
          lineHeight: `14px`,
          marginBottom: `0px`,
          [theme.breakpoints.down(`md`)]: {
            width: `540px`
          },
          [theme.breakpoints.down(`xs`)]:{
            width: `auto`
          }
        },
      }
    },
    offers: {
      "& h1": {
        fontSize: `1.3vw`,
        fontWeight: `700`,
      },
      // fontSize: `0.9vw`,
    },
    didYouKnow: {
      "& div": {
        marginBottom: `10px`,
        "& p": {
          lineHeight: `14px`,
          marginBottom: `10px`,
          marginTop: `5px`,
          fontSize: `12px`,
          [theme.breakpoints.down(`md`)]: {
            width: `540px`
          },
          [theme.breakpoints.down(`xs`)]:{
            width: `auto`
          }
        },
        "& ul": {
          lineHeight: `18px`,
          paddingInlineStart: `25px`,
          "& li": {
            listStyleType: `disc`,
            lineHeight: `14px`,
            fontSize: `12px`,
            [theme.breakpoints.down(`md`)]: {
              width: `540px`
            },
            [theme.breakpoints.down(`xs`)]:{
              width: `auto`
            }
          }
        }
      }
    },
    formHolder: {
      padding: `20px`,
      backgroundColor: `#ccc`,
      marginTop: `15px`,
      [theme.breakpoints.down(`sm`)]: {
        marginTop: `10px`,
        width: `auto`,
        "& form": {
          marginBottom: `0px`
        }
      },
    },
    groupContainer: {
      display: `flex`,
      margin: `6px 0px`,
    },
    labelContainer: {
      [theme.breakpoints.up(`md`)]: {
        width: `80px`,
        display: `inline`,
        float: `left`,
        marginLeft: `0px`,
        paddingTop:'2%',
        marginRight: `6px`,
        textAlign: `right`,
      },
      "& label": {
        color: `#666`,
      },
      "@media (max-width: 959px)": {
        width: `24%`,
        paddingTop:'2%',
        display: `flex`,
        fontSize: `14px`,
        "& div": {
          margin: `auto 0px`
        }
      },
      "@media (max-width: 770px)": {
        width: `15%`,
        marginLeft:'9%',
        paddingTop:'0px',
        display: `flex`,
        fontSize: `14px`,
        "& div": {
          margin: `auto 0px`
        }
      },
      "@media (max-width:760px)": {
        width: `28%`,
        display: `flex`,
        fontSize: `14px`,
        "& div": {
          margin: `auto 0px`
        }
      },
      "@media (max-width:550px)": {
        width: `19%`,
        display: `flex`,
        fontSize: `14px`,
        "& div": {
          margin: `auto 0px`
        }
      },
      "@media (max-width:520px)": {
        width: `28%`,
        display: `flex`,
        fontSize: `14px`,
        "& div": {
          margin: `auto 0px`
        }
      },
        "@media (max-width: 325px)": {
          width: ` 32%`,
          display: `flex`,
          fontSize: `14px`,
          "& div": {
            margin: `auto 0px`
          }
        },
    },
    fieldContainer: {
      [theme.breakpoints.up(`md`)]: {
        position: `relative`,
        width: `auto`,
        marginRight: `0`,
        display: `inline`,
        float: `left`,
        marginLeft: `15px`,
      },
      "@media (max-width:959px)": {
        width:'86%'
        },
      "@media (max-width:770px)": {
      width:'86%'
      },
      "@media (max-width:760px)": {
        width:'100%'
        },
      "& input, & select": {
        width: `205px`,
        height: `35px`,
        marginLeft: `0px`,
        "@media (max-width:959px)": {
          width:'86%'
          },
        "@media (max-width:770px)": {
        width:'86%'
        },
        "@media (max-width:760px)": {
          width:'100%'
          },
          "@media (max-width:550px)": {
            width:'88%'
            },
            "@media (max-width:520px)": {
              width:'100%'
              },
          
      },
      "& p": {
        color: `red`,
      },
    },
    footer: {
      backgroundColor: `#949494`,
      color: `#fff`,
      marginTop: `45px`,
      paddingTop: `3px`,
      paddingBottom: `3px`,
      lineHeight: `15px`,
      fontFamily: `'Open Sans', sans-serif`,
      fontSize: `12px`,
      textAlign: `center`,
      "& p": {
        margin: `0px`,
        [theme.breakpoints.down(`sm`)]: {
          fontSize: `12px`,
          textAlign: `center`,
          lineHeight: `16px`
        }
      },
      [theme.breakpoints.down(`sm`)]: {
        marginTop: `0px`
      }
    },
    logoContainer: {
      width: `70%`,
      ...mrAuto,
      ...mlAuto,
      paddingBottom: `5px`,
      [theme.breakpoints.down(`md`)]: {
        width: `auto`,
      },
    },
    privacyPolicy: {
      width: `235px`,
      fontSize: `10px`,
      marginTop: `10px`,
      lineHeight: `13px`,
      marginLeft: `70px`,
      "& a": {
        color: `-webkit-link !important`,
      },
      "& input": {
        [theme.breakpoints.up(`md`)]: {
          width: '1vw',
          height: '1vw'
        }
      },
      [theme.breakpoints.down(`sm`)]: {
        width: `auto`,
        marginTop: `10px`,
        marginLeft: `0px`,
        fontSize: `12px`,
        lineHeight: `16px`
      },

    },
    // fontstyleform: {
    //   fontSize: "1vw",
    // },
    submitButton: {
      textAlign: `center`,
      marginBottom: `0.7vw`,
      "& input": {
        border: `none`,
        borderRadius: `0.35vw`,
        height: `1.9vw`,
        color: `#fff`,
        [theme.breakpoints.down(`sm`)]: {
          height: `auto`,
          fontSize: `14px`,
          width: `25%`,
          marginTop: `5%`
        },
        [theme.breakpoints.down(`xs`)]: {
          width: `40%`,
        },
        marginTop: `0.5vw`,
        left: `0vw`,
        width: `15.9vw`,
        background: `linear-gradient(to bottom,  #1e5799 0%,#2989d8 50%,#207cca 51%,#7db9e8 100%)`,
        cursor: `pointer`,
      },
    },
    otpNumber: {
      display: `flex`,
      marginTop: `1.5vw`,
      width: `fit-content`,
      "& p": {
        margin: `0vw 0.3vw`,
      },
      "& input": {
        margin: `0vw 0.3vw`,
      },
    },
    modalButton: {
      display: `inline-block`,
      padding: `0.35vw 0.9vw`,
      margin: `0vw 0.1vw`,
      // fontSize: `1.4vw`,
      fontWeight: `400`,
      lineHeight: `1.42857143`,
      textAlign: `center`,
      whiteSpace: `nowrap`,
      verticalAlign: `middle`,
      cursor: `pointer`,
      border: `0.08vw solid #E5E5E5`,
      borderRadius: `0.32vw`,
      background: `none`,
    },
    contentTabsize: {
      fontSize: '12px',
      [theme.breakpoints.down(`sm`)]: {
        minWidth: `unset`,
        maxWidth: `unset`,
        width: `auto`,
        fontSize: '14px',
      },
    },
    formDivsize: {
      fontSize: '12px',
      lineHeight: '18px',
      [theme.breakpoints.down(`sm`)]: {
        width: `auto`,
        marginTop: `10px`,
        marginRight: `5px`,
        marginBottom: `5px`,
        marginLeft: `5px`,
      }
    },
    headerContainerSize: {
      width: '70%',
      [theme.breakpoints.down(`md`)]: {
        width: `auto`,
      },
    },
    selectStyle: {
      height: `35px`,
      width: `205px`,
      fontSize: `12px`,
      "@media (max-width: 992px)": {
        width: `300px !important`,
      },
    },
    offerTable: {
      border: `4px #1e5799 solid`,
      marginTop: `10px`,
      fontFamily: `12px`,
      "& table": {
        "& tr": {
          "& th": {
            borderBottom: `1px dashed #666`,
            borderRight: `1px dashed #ccc`,
            padding: `1px 5px`
          },
          "& td": {
            padding: `1px 5px`,
            borderRight: `1px dashed #ccc`,
            borderBottom: `1px dashed #ccc`
          }
        }
      }
    },
    carouselImage: {
      [theme.breakpoints.down(`lg`)]: {
        "& img":{
          marginLeft: `auto`,
          marginRight: `auto`
        }
      },
      [theme.breakpoints.down(`sm`)]: {
        "& img": {
          height: `100px`,
          width: `100%`
        }
      }
    },
    lastTab: {
      [theme.breakpoints.down(`md`)]:{
        marginRight: `0px`
      }
    }
  };
}
