import React from "react";
import {
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import root from "window-or-global";
import starBulletin from "ps-assets/img/star-bulletin.png";
import ModalForm from "ps-components/ModalForm/ModalForm";
import FreeQuoteStyle from "ps-assets/jss/FreeQuoteStyle";
import logo from "ps-assets/img/transparent-logo.png";
import banner1 from "ps-assets/img/banner1.png";
import banner2 from "ps-assets/img/banner2.png";
import banner3 from "ps-assets/img/banner3.png";
import Carousel from "react-slick";
import classNames from "classnames";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import { landingPageLMSCall } from "util/lmsRequest";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout";
import {  browserWindow } from "util/localStorageHelper";
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;

const imagesArr = [banner1, banner2, banner3];

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  pincode: `^[1-9][0-9]{5}$`,
};

const tableProperties = {
  columns: [`FEATURES`, `STAR HEALTH`, `OTHER INSURERS`],
  data: [
    [`Cashless Approvals`, `2 Hour`, `4 Hours - 1 Day`],
    [`Average Claim Settlement`, `15 Days`, `1 Month`],
    [`Claims Handling`, `In House claim settlement`, `TPA`],
    [`Network Hospitals`, HOSPITALCOUNT, `3000-4000`],
    [
      `24X7 TeleMedical Assistance`,
      `Provided`,
      `Majority of others dont provide.`,
    ],
    [
      `24X7 TeleMedical Assistance`,
      `Provided`,
      `Majority of others dont provide.`,
    ],
    [
      `Pre Medical Screening
      Cost`,
      `Completely Borne by our Company`,
      `Partially Borne`,
    ],
    [`Value Added Services`, `Available`, `NA`],
    [`Lifetime Renewal`, `Guaranteed`, `Offered`],
  ],
  options: {
    selectableRows: false,
    sort: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: `scroll`,
    pagination: false,
    textLabels: {
      body: {
        noMatch: ``,
      },
    },
  },
  getMuiTheme: () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: `red`,
          },
          paper: {
            boxShadow: `none`,
            paddingBottom: `1.5vw`,
          },
          responsiveScroll: { maxHeight: `auto !important` },
        },
        MuiToolbar: {
          root: {
            display: `none !important`,
          },
        },
        MuiTableCell: {
          head: {
            fontWeight: `bold !important`,
            fontSize: `1.3vw`,
          },
          body: {
            fontSize: `1vw`,
            fontWeight: `300`,
          },
        },
        MuiTableSortLabel: {
          icon: {
            marginRight: `0px`,
          },
        },
      },
    }),
};



class FreeHealthQuote extends React.Component {
  state = {
    currentTab: 0,
    name: ``,
    email: ``,
    mobile: ``,
    pincode: ``,
    privacyPolicy: false,
    submitButton: false,
    showOTP: false,
    otpNumber: ``,
    otpError: ``
  };
  settings = {
    dots: true,
    infinite: true,
    arrows: true,
    autoplaySpeed: 7000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dotsClass: `vertical-dots`,
    adaptiveHeight: true,
    beforeChange: (current, next) => {
      this.currentSlide = next;
    },
  };

  changeTab = (key) => {
    this.setState({
      currentTab: key,
    });
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case `name`:
          this.setState({ [e.target.id]: e.target.value });
        break;
      case `pincode`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `mobile`:
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `otpNumber`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value, otpError: `` });
        }
        break;
      default:
        this.setState({ [e.target.id]: e.target.value });
        break;
    }
  };

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    });
  };

  generateOTP = () => {
    let generateOTP_API = `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/token/generateOtp?mobileNo=${this.state.mobile}&serviceType=prelogin`;
    fetch(generateOTP_API).then(res => {
      if (res.status === 200) {
        this.setState({
          showOTP: true
        });
      }
      else {
        this.setState({
          otpError: `Invalid Mobile Number`
        });
      }
    }).catch(() => this.setState({
      otpError: `Network Problem. Please try again later`
    }))
  }

  validateOTP = async () => {
    let validateOTP_API = `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/token/validateOtp?mobileNo=${this.state.mobile}&otp=${this.state.otpNumber}`;
    fetch(validateOTP_API).then(async res => {
      if (res.status === 200) {
        this.sendToLMS(`complete-health-insurance`);
      }
      else {
        this.setState({
          otpError: `Invalid OTP`
        });
      }
    }).catch(() => {
      this.setState({
        otpError: `Network Problem. Please try again later`
      })
    })
  }


  sendToLMS = async (prevLandingPage) => {
    try {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.state.pincode
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, prevLandingPage);
      root.open(`${browserWindow.location.pathname}/thank-you?otp=yes&userId=${response.zohoEntityId ? response.zohoEntityId : ``}${queryParams ? `&${queryParams}` : ``}`, `_self`);
    }
    catch (exception) {
      console.error(`connection failed - `, exception);
    }
  };


  proceed = () => {
    switch (this.props.pageContext.source) {
      case `all-policies-old`:
        this.sendToLMS(`all-policies-landing-old`);
        break;
      default:
        this.generateOTP();
        break;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitButton: true,
      otpError: ``
    });
    if (this.state.name !== ``) {
      if (this.state.email.search(/^\S+@\S+\.\S+$/) === 0) {
        if (this.state.mobile.search(validationConditions.mobile)==0) {
          if (this.state.privacyPolicy) {
            if (this.state.pincode.search(validationConditions.pincode)==0) {
              this.sendToLMS();
            }
          }
        }
      }
    }
  };

  render() {
    const { classes } = this.props;

    const WhyStar = () => {
      const whyStarPoints = [
        `89.9% of cashless claims settled in less than 2 hours.`,
        `6 Easy Steps for Cashless Claim Process. Avail Cashless Benefits Across `+HOSPITALCOUNT+` Hospitals.`,
        `Hassle free and customer friendly direct claim settlement without intervention of TPA`,
        `75 Lakh+ claims settled since inception.`,
        `Rs.30,385 Crs of claims amount paid out since inception.`,
        `Wide range of health insurance products from family floater schemes to senior citizen health coverage.`,
        `Pre & Post hospital medical expenses. Up to 100% increase in sum insured up on a claim free renewal.`,
      ];
      return (
        <div className={classes.whyStar}>
          <b>Why Star Health?</b>
          <div className={classes.whyStarDetails}>
            {whyStarPoints.map((point, key) => (
              <div key={key}>
                <img src={starBulletin}/>
                <p>{point}</p></div>
            ))}
          </div>
        </div>
      );
    };

    const ProductOfferings = () => (
      <div className={classes.productOffering} >
        <b>What we offer?</b>
        <div>
          <b>Star Comprehensive health insurance:</b>
          <p>
            Star Comprehensive Insurance Policy offers wide coverage for the entire
            family under a single sum insured option. This plan also includes
            extraordinary features such as cashless hospitalization benefits with no
            capping on room rent and treatment costs, coverage for outpatient Dental
            & Ophthalmic treatments, maternity cover, free health checkup and much
            more with guaranteed lifetime renewals.
          </p>
        </div>
        <div>
          <b>Star Senior Citizen Red Carpet:</b>
          <p>
            Star Senior Citizen Red Carpet is a health insurance policy exclusively
            meant for Senior citizens aged between 60 to 75 years with guaranteed
            renewals beyond 75 years.This plan brings a whole lot of exciting
            features such as No pre-medical hassles, Pre-existing diseases covered
            from 1st year of policy, Higher Sum Insured coverage etc.
          </p>
        </div>
      </div>
    );

    const Offers = () => (
      <div className={classes.offers}>
        <b>What We Offer & Others dont</b>
        <div className={classes.offerTable}>
          <table cellPadding="0" cellSpacing="0" border="0">
            <tbody>
              <tr>
                {tableProperties.columns.map((tHead, tHeadIndex) => <th key={`table-head-${tHeadIndex}`}>{tHead}</th>)}
              </tr>
              {tableProperties.data.map((tRow, tRowIndex) => <tr key={`table-row-${tRowIndex}`}>{tRow.map((tCell, tCellIndex) => <td key={`table-cell-${tCellIndex}`}>{tCell}</td>)}</tr>)}
            </tbody>
          </table>
        </div>
      </div>
    );

    const DidKnow = () => (
      <div className={classes.didYouKnow}>
        <b>Did You Know?</b>
        <div>
          <b>Why Health Insurance in India is a must?</b>
          <p>Health industry is advancing in a competent pace with the invention of new equipment’s and technology. Private hospitals provide a competent treatment in terms of quality and technology. Everyone opt for a quality treatment which is considerably expensive. Health Insurance reduces this burden and paves way for easy access to quality health to all. It is an absolute security for family and aids you at the time of emergency and minimizes your monetary burdens.</p>
        </div>
        <div>
          <b>Is there an Income Tax exemption under Health policy?</b>
          <p>Premium paid under the Health insurance Policy is exempted from Income
          Tax under section 80D of the Income Tax Act up to Rs.25,000 for
            individual covering his family and dependent Children.</p>
          <p>In case the proposer intends to cover his parents under medical
          insurance, he is eligible for a deduction of another Rs.30,000 under
          section 80D.
          </p>
          <p>In case the age of parent to be covered is above 65 years, the deduction
          available is Rs.20,000 under section 80D.</p>
        </div>
        <div>
          <b>What are the expenses covered by a health insurance?</b>
          <p style={{ marginBottom: `0px` }}>A Health Insurance Policy ideally covers -</p>
          <ul>
            <li>
              In-Patient Treatment - Pays for the expenses incurred in
              hospitalization due to an illness or accident. It covers charges for
              medical practitioner, nursing, ICU charges, medicine, room rent and
              other related charges.
            </li>
            <li>
              Pre-Hospitalization - Expenses incurred due to the illness
              immediately before hospitalization.
            </li>
            <li>
              Post - Hospitalization - Expenses incurred immediately after the
              discharge from hospital.
            </li>
            <li>
              Day - care procedures - Expenses for the cases that donot require 24
              hours hospitalization.
            </li>
          </ul>
        </div>
        <div>
          <b>What do you mean by Cashless Hospitalization?</b>
          <p>In the event of hospitalization, the patient or their family will have a
          bill to pay the hospital. Under Cashless Hospitalization the patient
          does not settle the hospitalization expenses at the time of discharge
          from hospital. The settlement is done directly by Star Health Insurance.</p>
        </div>
        <div>
          <b>What is the maximum number of claims allowed over a year?</b>
          <p>Any number of claims is allowed during the policy period. However the
            sum insured is the maximum limit under the policy.</p>
        </div>
        <div>
          <b>What is the Family Floater Plan?</b>
          <p>One single policy takes care of the hospitalization expenses of your
          entire family. Family Floater Health Plan takes care of all the medical
          expenses during sudden illness, surgeries and accidents.</p>
        </div>
        <div>
          <b>
            What are the crucial factors to check with the health insurance
            policy?
          </b>
          <ul>
            <li>List of hospitals tied up with the insurance company.</li>
            <li>
              Exclusions in the policy should be read thoroughly to avoid chaos at
              the time of claim.
            </li>
          </ul>
        </div>
      </div>
    );

    const listTabs = [
      { title: `Why Star Health?`, component: <WhyStar /> },
      { title: `Product Offerings`, component: <ProductOfferings /> },
      { title: `What we offer & Other dont`, component: <Offers /> },
      { title: `Did You Know?`, component: <DidKnow /> },
    ];
    return (
      <InstantBuyLayout noHeader={true} noFooter={true} >
        <div style={{ background: 'white' }}>
          <ModalForm
            title=""
            show={this.state.showOTP}
            customStyle={`freeQuote`}
            handleClose={() => this.setState({ showOTP: false, otpNumber: `` })}
          >
            <div>
              <p style={{ fontWeight: "bold", margin: "15px 0px" }}>STAR Health - OTP Validation</p>
              <p>
                We have sent the OTP details to the Mobile number mentioned in
                the form. Please key-in this number to proceed further.
              </p>
              <p style={{ fontStyle: "italic" }}>
                Kindly wait for 2 minutes after which you can use the Resend
                button to request OTP again.
              </p>
              <div className={classNames(classes.otpNumber, classes.mlAuto, classes.mrAuto)}>
                <p>Enter OTP:</p>
                <input
                  type="text"
                  name="otpNumber"
                  id="otpNumber"
                  value={this.state.otpNumber}
                  onChange={this.handleChange}
                />
              </div>
              <div className={classNames(classes.otpNumber, classes.mlAuto, classes.mrAuto)}>
                <button className={classes.modalButton} type="button" onClick={this.validateOTP}>Proceed</button>
                <button className={classes.modalButton} type="button" onClick={this.generateOTP}>Resend OTP</button>
              </div>
              <p style={{ marginTop: `10px`, color: `red`, textAlign: `center` }}>{this.state.otpError}</p>
            </div>
          </ModalForm>
          <div style={{ overflow: `auto` }}>
            <div className={classes.topBlueStrip} />
            <div
              className={classes.headerWrapper}>
              <div className={classes.logoContainer}>
                <img src={logo} />
              </div>
            </div>
            <div
              className={classNames(
                classes.container,
                classes.mrAuto,
                classes.mlAuto,
                classes.headerContainerSize
              )}
            >
              <Carousel {...this.settings}>
                {imagesArr.map((img, key) => (
                  <div className={classes.carouselImage} key={key}>
                    <img src={img}/>
                  </div>
                ))}
              </Carousel>
            </div>
            <div
              className={classNames(
                classes.container,
                classes.contentDiv,
                classes.mlAuto,
                classes.mrAuto
              )}

            >
              <div className={classNames(classes.container, classes.content)}>
                <div className={`${classes.contentTabs} ${classes.contentTabsize}`} >
                  <div>
                    <ul className={classes.etabs}>
                      {listTabs.map((item, key) => (
                        <li
                          onClick={() => this.changeTab(key)}
                          className={`${classes.tab} ${
                            key === listTabs.length - 1 ? classes.lastTab : ``
                          } ${
                            key === this.state.currentTab
                              ? `${classes.activeTab}`
                              : ``
                          }`}
                          key={key}
                        >
                          <p>{item.title}</p>
                        </li>
                      ))}
                    </ul>
                    <div className={classes.panelContainer}>
                      {listTabs[this.state.currentTab].component}
                    </div>
                  </div>
                </div>
                <div className={`${classes.formDiv} ${classes.formDivsize}`} >
                  <span>Please Fill in Your Details to Get a Free Quote</span>
                  <div className={classes.formHolder}>
                    <form onSubmit={this.handleSubmit} method="post">
                      <div className={classes.groupContainer}>
                        <div className={classes.labelContainer}>
                          <div>Name :</div> {/*  className={classes.fontstyleform} */}
                        </div>
                        <div className={classes.fieldContainer}>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                          {this.state.submitButton && this.state.name.length<3 && (
                            <p>Name is not in the required format</p>
                          )}
                        </div>
                      </div>
                      <div className={classes.groupContainer}>
                        <div className={classes.labelContainer}>
                          <div className={classes.fontstyleform}>Email Id :</div>

                        </div>
                        <div className={classes.fieldContainer}>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                          {this.state.submitButton &&
                            this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                              <p>Please enter a valid email address.</p>
                            )}
                        </div>
                      </div>
                      <div className={classes.groupContainer}>
                        <div className={classes.labelContainer}>
                          <div className={classes.fontstyleform}>Mobile :</div>

                        </div>
                        <div className={classes.fieldContainer}>
                          <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            value={this.state.mobile}
                            onChange={this.handleChange}
                          />
                          {((this.state.submitButton && this.state.mobile.length < 10) || (this.state.submitButton && this.state.mobile.search(`^[6-9][0-9]{9}$`) == -1)) && (
                              <p>Enter 10 Digit Phone Number</p>
                            )}
                        </div>
                      </div>
                      <div className={classes.groupContainer}>
                        <div className={classes.labelContainer}>
                          <div className={classes.fontstyleform}>PinCode :</div>

                        </div>
                        <div className={classes.fieldContainer}>
                          <input
                            type="text"
                            id="pincode"
                            name="pincode"
                            value={this.state.pincode}
                            onChange={this.handleChange}
                          />
                          {((this.state.submitButton && this.state.pincode.length < 6) || (this.state.submitButton && this.state.pincode.search(`^[1-9][0-9]{5}$`) == -1)) && (
                              <p>Enter 6 Digit Valid PinCode</p>
                            )}
                        </div>
                      </div>
                      <div
                        className={classNames(
                          classes.privacyPolicy,
                          classes.mlAuto,
                          classes.mrAuto
                        )}
                      >
                        <input
                          onClick={this.handlePrivacyPolicy}
                          checked={this.state.privacyPolicy}
                          type="checkbox"
                        />
                        {` I have read and agree to the `}
                        <a
                          style={{ cursor: `pointer`, textDecoration: "underline" }}
                          href="/static-privacy-policy"
                          target="_blank"
                          ref={`noopener noreferrer`}
                        >
                          Privacy Policy
                    </a>
                        {` I authorize Star Health Insurance to
                    contact me via email or phone or SMS.`}
                      </div>
                      {this.state.submitButton && !this.state.privacyPolicy && (
                        <p style={{ color: `red`, textAlign: `center` }}>
                          Please Agree to the Terms
                        </p>
                      )}
                      {this.state.otpError !== `` && (
                        <p style={{ color: `red`, textAlign: `center` }}>
                          {this.state.otpError}
                        </p>
                      )}
                      <div className={classes.submitButton} >
                        <input type="submit" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                classes.footer,
                classes.mrAuto,
                classes.mlAuto
              )}
            >
              <p style={{ fontSize: `15px` }}>
                IRDAI REGISTRATION NUMBER. 129
          </p>
              <p>
                For complete details on coverage, terms, conditiions and exclusions,
                please read the Policy document carefully. Insurance is the subject
                matter of solicitation.
          </p>

              <p style={{ marginTop: `10px`, fontSize: `15px`, fontWeight: "bold" }}>
                Star Health and Allied Insurance Company Limited.{" "}
              </p>
              <p>
                Corporate Office: No.1, New Tank Street, Valluvar Kottam High Road,
                Nungambakkam, Chennai - 600034. Sales & Services - 044 4674 5800
          </p>
              <p style={{ marginTop: `10px` }}>
                For further details visit{" "}
                <a style={{ color: whiteColor }} href="http://starhealth.in">
                  www.starhealth.in
            </a>{" "}
            | SHAI/17-18/Online/LP/BuyOnline/008
          </p>
            </div>
          </div>
        </div>
      </InstantBuyLayout>
    );
  }
}

FreeHealthQuote.defaultProps = {
  pageContext: {
    source: ``
  }
}

FreeHealthQuote.propTypes = {
  classes: PropTypes.object,
  pageContext: PropTypes.object
}

export default withStyles(FreeQuoteStyle)(FreeHealthQuote);
